<template>
  <div id="app">
    <Tracker :token="token" :iframe="iframe"/>
  </div>
</template>

<script>
import Tracker from './components/Tracker.vue'

export default {
  name: 'App',
  components: {
    Tracker
  },
  props:{
    token: { default: null},
    iframe: {default: null}
  }
}
</script>