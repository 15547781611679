import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import UUID from "vue-uuid";
import axios from 'axios'
import VueAxios from 'vue-axios'
import UAParser from 'ua-parser-js';
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element';

UAParser.install = function(){
  Object.defineProperty(Vue.prototype, '$uaparser', {
    get () { return new UAParser }
  })
}
Vue.use(UAParser);
Vue.config.productionTip = false
Vue.use(VueCookies);
Vue.use(UUID);
Vue.use(vueCustomElement);

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  params: {}
});

Vue.use(VueAxios, instance);
Vue.customElement('moby-tracker', App)