<template>
  <div class="">
  </div>
</template>
<script>
export default {
  name: "Tracker",
  props: {
    token: { default: null },
    iframe: { default: null },
  },
  data() {
    return {
      user: {
        uid: null,
        referrer: null,
        resolution: { height: null, width: null },
        user_agent: null,
        utm: {
          source: null,
          medium: null,
          term: null,
          content: null,
          campaign: null,
        },
        utm_general: null,
        ip: null,
        city: null,
        country: null,
        latitude: null,
        longitude: null,
        isp: null,
        browser: null,
        version: null,
        platform: null,
        token: this.token,
      },
    };
  },
  beforeMount() {
    this.scope_agent();
  },
  created() {
    this.set_cookie();
  },
  mounted() {
    this.sent_to_server();
	this.set_utm();
	if(this.iframe){
		this.setIframeData();
	}
  },
  methods: {
    set_utm() {
      this.user.utm_general = this.findGetParameter("utm");
      this.user.utm.source = this.findGetParameter("utm_source");
      this.user.utm.medium = this.findGetParameter("utm_medium");
      this.user.utm.campaign = this.findGetParameter("utm_campaign");
      this.user.utm.term = this.findGetParameter("utm_term");
      this.user.utm.content = this.findGetParameter("utm_content");
    },
    findGetParameter(parameterName) {
      var result = null,
        tmp = [];
      location.search
        .substr(1)
        .split("&")
        .forEach(function(item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
      return result;
    },
    sent_to_server() {
      this.axios.get("https://ipapi.co/json/").then((result) => {
        this.user.ip = result.data.ip;
        this.user.city = result.data.city;
        this.user.country = result.data.country;
        this.user.latitude = result.data.latitude;
        this.user.longitude = result.data.longitude;
        this.user.isp = result.data.org;
        this.axios.post("/add", { user: this.user }).then(() => {});
      });
    },
    set_uid() {
      this.user.uid = this.$uuid.v1();
    },
    scope_agent() {
      let user_agent = this.$uaparser.getResult();
      this.user.referrer = document.referrer;
      this.user.resolution.height = window.screen.availHeight;
      this.user.resolution.width = window.screen.availWidth;
      this.user.browser = user_agent.browser.name;
      this.user.version = user_agent.browser.version;
      this.user.platform = user_agent.os.name;
    },
    set_cookie() {
      if (this.$cookies.get("mb-tracker")) {
        this.cookie = this.$cookies.get("mb-tracker");
        this.user.uid = this.$cookies.get("mb-tracker");
      } else {
        this.set_uid();
        this.$cookies.set("mb-tracker", this.user.uid, "expiring time");
        this.user.cookie = this.user.uid;
      }
    },
    setIframeData() {
      let trackerId = this.$cookies.get("mb-tracker")
      if(document.getElementById(this.iframe)){
        let quoteUrl = document.getElementById(this.iframe).src
        document.getElementById(this.iframe).src = `${quoteUrl}?moby_tracker=${trackerId}`
      }
    },
  },
};
</script>
<style scoped></style>
